import { __decorate, __extends } from "tslib";
/**
 *  Created by pw on 2020/9/7 3:34 下午.
 */
import { Actions, action, reducer } from '@ekuaibao/store';
import { Resource } from '@ekuaibao/fetch';
import { ID } from './keys';
import { showMessage } from '@ekuaibao/show-util';
var propertyAction = new Resource('/api/flow/v2/propertySet');
var FieldSettingAction = /** @class */ (function (_super) {
    __extends(FieldSettingAction, _super);
    function FieldSettingAction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FieldSettingAction.prototype.disableField = function (params) {
        return { payload: propertyAction.POST('/property/$name/$active', params) };
    };
    FieldSettingAction.prototype.saveGlobalField = function (data) {
        return {
            payload: propertyAction.POST('/property', data),
        };
    };
    FieldSettingAction.prototype.changeLanguages = function (params) {
        return {
            payload: propertyAction.PUT('/languages', params),
        };
    };
    FieldSettingAction.pluginName = ID;
    __decorate([
        reducer,
        action
    ], FieldSettingAction.prototype, "disableField", null);
    __decorate([
        reducer(function (action) {
            handleError(action, i18n.get('添加成功'));
            return action.payload.value;
        }),
        action
    ], FieldSettingAction.prototype, "saveGlobalField", null);
    __decorate([
        reducer(function (action) {
            handleError(action, i18n.get('修改成功'));
            return action.payload.value;
        }),
        action
    ], FieldSettingAction.prototype, "changeLanguages", null);
    return FieldSettingAction;
}(Actions));
function handleError(action, msg) {
    if (!action) {
        return;
    }
    var payload = action.payload, error = action.error;
    var errorMsg = payload.message || payload.msg;
    if (error) {
        showMessage.error(errorMsg);
        return;
    }
    showMessage.success(msg);
}
var fieldSettingAction = new FieldSettingAction();
export default fieldSettingAction;
