/**
 *  Created by pw on 2020/9/8 6:46 下午.
 */
export var ID = '@field-setting';
export default {
    ID: ID,
    DISABLE_FIELD: ID + "/DISABLE_FIELD",
    SAVE_FIELD: ID + "/SAVE_FIELD",
    CHANGE_LANGUAGE: ID + "/CHANGE_LANGUAGE",
};
