var _a;
import loadable from '@loadable/component';
import fieldSettingAction from './field-setting.action';
export var Universal_Unique_Key = 'fieldSetting.pc';
export default [
    {
        id: '@field-setting',
        reducer: function () { return fieldSettingAction.getReducer(); },
        path: '/field-setting',
        ref: '/',
        exact: true,
        onload: function () { return import('./FieldSettingView'); },
    },
    {
        point: '@@layers',
        prefix: '@field-setting',
        onload: function () { return import('./layer'); },
    },
    {
        resource: '@field-setting',
        value: (_a = {},
            _a['components/LanguagePanel'] = loadable(function () { return import('./components/LanguagePanel'); }),
            _a),
    },
];
